
  @import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');


body {
    background-color: #090A0F;
    font-family: 'DM Sans', sans-serif;
}

.navbar-header {
    width: 100%;
}

.navbar.scrolled .nav-btn {
    padding: 3% !important;
    text-align: center;
    width: 120px;
    background: linear-gradient(#ffffff, #fff) padding-box,
        linear-gradient(to right, darkblue, darkorchid) border-box;
    border-radius: 10px;
    border: 2px solid transparent;
}
.navbar-nav a img{
    width: 30px;

}

.navbar.scrolled {
    top: 0;
    padding-bottom: 10px;
    background: rgb(255, 255, 255);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    border-top: 0;
    border-left: 0;
}


.navbar.scrolled a {
    color: #000;
}


.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 2.5rem;
    font-size: 18px;
    font-weight: 500;

    
}

.nav-a {
    margin-top: 1%;
}

.nav-item {
    font-weight: 700;
    color: white;
    font-size: 0px;
    padding-right: 2%;
}

.nav-item  {
    font-weight: 500;
    color: white;

}

.nav-btn {
    padding: 3% !important;
    text-align: center;
    width: 120px;
    background: linear-gradient(#030513, #030513) padding-box,
        linear-gradient(to right, darkblue, darkorchid) border-box;
    border-radius: 10px;
    border: 2px solid transparent;
}


.navbar {
    padding-top: 15px;
    position: fixed;
    z-index: 10;
}


.un-hide {
    visibility: visible;
}

.hide {
    visibility: hidden;
}

.hero {
    background-image: url("../Images/bg.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 95%;
    min-height: 100vh;
}

.detail_page{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}




.hero h1 {
    margin-top: 20%;
    color: #FFF;
    text-align: right;
    text-shadow: 0 0 10px rgb(157 97 233 / 65%);
    font-size: 50px;
    font-weight: 700;
    text-transform: capitalize;
}

.hero span {
    color: #FFF;
    text-align: right;
    text-shadow: 0 0 10px rgb(157 97 233 / 65%);
    font-size: 25px;
    font-weight: 500;
    text-transform: capitalize;
}

.hero-content {
    display: flex;
    flex-direction: column;
    align-items: right;
    padding: 8% 0% 12% 45%;
}

.animate-gradient {
    -webkit-text-fill-color: transparent;
    background: linear-gradient(270deg,#ff7356,#ff59e2 25.52%,#52ddf6 50%,#eadf4e 76.04%,#ff7356);
    -webkit-background-clip: text;
    background-clip: text;
    background-size: 200% auto;
    animation: gradient 6s linear infinite;
}

.hero-content p {
    color: #FFF;
    text-align: right;
    font-size: 18px;
    margin-top: 10px;
    font-weight: 400;
}

.hero-content img {
    width: 60%;
}

.hero-content a {
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    text-decoration: none;
    margin-left: 80%;
    padding: 1% 2% !important;
    text-align: right;
    background: linear-gradient(#070d35, #100632) padding-box, linear-gradient(to right, darkblue, darkorchid) border-box;
    border-radius: 10px;
    border: 2px solid transparent;
    transition: all .4s ease-in-out;    min-width: 112px;
}




.hero-content a:hover {
    -webkit-box-shadow: 0px 0px 10px 1px rgba(123, 19, 255, 0.5);
    -moz-box-shadow: 0px 0px 10px 1px rgba(123, 19, 255, 0.5);
    box-shadow: 0px 0px 66px 8px rgb(2 48 147);
}


.sub-category-card {
    background-position: bottom;
    background-size: cover;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    height: 400px;
    cursor: pointer;
    margin-bottom: 50px;
}

.bl-bg-ser1 {
    background-image: url("../Images/services/blockchain/ico.webp");
}
.bl-bg-ser2 {
    background-image: url("../Images/services/blockchain/mlm.webp");
}
.bl-bg-ser3 {
    background-image: url("../Images/services/blockchain/cefi.webp");
}
.bl-bg-ser4 {
    background-image: url("../Images/services/blockchain/token.webp");
}
.bl-bg-ser5 {
    background-image: url("../Images/services/blockchain/defi.webp");
}
.bl-bg-ser6 {
    background-image: url("../Images/services/blockchain/lanchpad.webp");
}
.bl-bg-ser7 {
    background-image: url("../Images/services/blockchain/nft.webp");
}
.bl-bg-ser8 {
    background-image: url("../Images/services/blockchain/stacking.webp");
}
.bl-bg-ser9 {
    background-image: url("../Images/services/blockchain/gaming.webp");
}
.bl-bg-ser10 {
    background-image: url("../Images/services/blockchain/bot.jpg");
}

.bl-bg-ser11 {
    background-image: url("../Images/services/blockchain/payment.jpg");
}

.ai-bg-ser1 {
    background-image: url("../Images/services/ai/artificial-intelligence-humanoid-head-with-neural-network-thinks-futuristic-modern-3d-illustration.webp");
}

.graphic-bg-ser1 {
    background-image: url("../Images/services/graphics/graphics.webp");
}
.graphic-bg-ser2 {
    background-image: url("../Images/services/graphics/digitalart.webp");
}
.graphic-bg-ser3 {
    background-image: url("../Images/services/graphics/logo.webp");
}

.web-bg-ser1 {
    background-image: url("../Images/services/website/bg.webp");
}

.web-bg-ser2 {
    background-image: url("../Images/services/website/mobile.webp");
}


.ico_bg{
    background-image: url("../Images/bg/ico.webp");
    opacity: 80%;
}
.mlm_bg{
    background-image: url("../Images/bg/mlm.webp");
    opacity: 80%;
}
.cex_bg{
    background-image: url("../Images/bg/cefi.webp");
    opacity: 80%;
}
.dex_bg{
    background-image: url("../Images/bg/defi.webp");
    opacity: 80%;
}
.token_bg{
    background-image: url("../Images/bg/token.webp");
    opacity: 80%;
}
.lanchpad_bg{
    background-image: url("../Images/bg/lanchpad.webp");
    opacity: 80%;
}

.clientscards {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0c0d11 !important;
    border: 1px solid #fff;
    border-radius: 10px !important;
}

.clientscards:hover{
    background: #000 !important;
    border: 1px solid #000 !important;
}

.clientscards > div {
    display: flex;
    flex-direction: column;
    height: 100%; /* Ensure the container takes up the full height */
}

.clienthead{
    font-size: 40px !important;
    color: #fff !important;
    font-weight: 800;
    text-align: center;
    
}
.clientscards img{
    max-height: 100px !important;
}

.nft_bg{
    background-image: url("../Images/bg/nft.webp");
    opacity: 80%;
}
.staking_bg{
    background-image: url("../Images/bg/staking.webp");
    opacity: 80%;
}
.gaming_bg{
    background-image: url("../Images/bg/gaming.webp");
    opacity: 80%;
}
.bot_bg{
    background-image: url("../Images/bg/bot.jpg");
    opacity: 80%;
}
.payment_bg{
    background-image: url("../Images/bg/payment.jpg");
    opacity: 80%;
}
.graphics_bg{
    background-image: url("../Images/bg/graphics.webp");
    opacity: 80%;
}
.arts_bg{
    background-image: url("../Images/bg/arts.webp");
    opacity: 80%;
}
.logo_bg{
    background-image: url("../Images/bg/logo.webp");
    opacity: 80%;
}
.web_bg{
    background-image: url("../Images/bg/mobile.webp");
    opacity: 80%;
}
.app_bg{
    background-image: url("../Images/bg/mobile.webp");
    opacity: 80%;
}

.right h2{
    text-align: right;    
}



.sub-category-card-content {
    padding: 11px 20px;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 20%;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    left: 0;
    overflow: hidden;
    background: linear-gradient(rgba(19, 2, 36, 0.6), rgba(0, 0, 0, 0.5));
    transition: 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
}

.sub-category-card:hover .sub-category-card-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.sub-category-card:hover .sub-category-card-content-desc {
    opacity: 1;
}



.sub-category-card:hover .sub-category-card-content-title {
    margin: 0.5%;
}


.sub-category-card-content p {
    color: #fff;
}

.sub-category-card-content-title {
    font-weight: 700;
    font-size: 1.25em;
    transition: 0.3s ease-in-out;
}

.sub-category-card-content-desc {

    font-size: 16px;
    color: #fff;
    font-weight: 500;
    opacity: 0;
    transition: 0.3s ease-in-out;
}

.nav-pills {
    display: flex;
    width: 75%;
    justify-content: center;
    margin: 0 auto;
}

.sub-tab {
    font-size: 15px;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    border-bottom: 2px solid #fff;
    background: none;
    border-radius: 0;
}

.sub-category {
    padding: 8% 0;
}

.tab-row {
    padding-bottom: 5%;
}

.nav-pills .nav-link {
    color: #ffffff7a;
}



.sub-section-h1 {
    font-size: 6rem;
    color: #FFF;
    text-align: center;
    font-weight: 700;
}

.sub-section-h2 {
    font-size: 3rem;
    color: #FFF;
    text-align: center;
    font-weight: 700;
    padding: 0% 0 1.5%;
}

.benefits-sub-tab{
    padding: 5% 0;
    background-color: #0000005e;
}

.txt-l{
    text-align: left;
}

.justify-center{
    display: flex;
    justify-content: center;
}

.flex{
    display: flex;
}

.flex-col{
    display: flex;
    flex-direction: column;
}

.screenshot-container{
    color: #FFF;
}

.screenshot-title{
    font-size: 3rem;
    font-weight: 500;
    margin-left: auto;
    margin-right: auto;
}

.align-center{
    display: flex;
    align-items: center;
}

.screenshot-left img{
    width: 100%;
}

.margin-10-per{
    margin: 5% 0 5%;
}

.sub-tab-card {
    text-align: center;
    padding: 20px;
    min-height: 90px;
    margin-bottom: 10px;
    border-radius: 10px;
    border: 2px solid rgba(255, 255, 255, 0.45);
    background: rgba(255, 255, 255, 0.16);
    backdrop-filter: blur(125px);
    display: grid;
    place-content: center;
}


.sub-card-p{
    font-size: 16px;
    color: #FFF;
}

.margin-zero {
    margin: 0;
}

.section-padding {
    padding: 5% 0;
}

.section-desc{
    color: white;
    font-size: 16;
    text-align: center;
}

.container-iframe {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
  }
  
  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
  }


  .copyrights {
    align-items: center;
  }
  
  .copyrights hr {
    width: 50%;
    background: linear-gradient(white, white) padding-box,
      linear-gradient(to right, darkblue, darkorchid) border-box;
    border-top: 1px solid transparent;
    opacity: 1;
  
  }
  
  .copyrights h4 {
    text-align: center;
    font-size: 14px;
    color: rgb(255, 255, 255);
    padding-top: 1%;
    padding-bottom: 2%;
  }
  

@media only screen and (max-width: 767px) {
    body{
        background-color: none;
    }
    .hero-content h1 {
        font-size: 1.5rem;
       text-align: center;
    }

    .hero-bg {
        backdrop-filter: blur(2px);
    }

    .hero-content a {
        font-size: 15px;
        font-weight: 400;
        color: #fff;
        text-decoration: none;
        padding: 1% 2% !important;
        background: linear-gradient(#070d35, #100632) padding-box, linear-gradient(to right, darkblue, darkorchid) border-box;
        border-radius: 10px;
        display: block;
        margin: 0 auto;
        border: 2px solid transparent;
        transition: all .4s ease-in-out;
    }
    


    .hero {
        background-image: url("../Images/block.webp");
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 100vh;
    }

    .hero-content p {
        text-align: center;
        font-weight: 400;
    }

    .hero-content img {
        width: 100%;
        
    }

    .hero-content {
        padding: 0px;
        display: flex;
  justify-content: center;
  align-items: center;
        
    }

    .margin-10-per{
        margin: 5% 0 25%;
    }

    .hero-content button{
        padding: 1% 2% !important;
        text-align: center;
        padding: 0%;
        margin: 0%;
    }
    

    .hero {
        height: 100vh;
    }

    .hero-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100vh;
    }

    .nav-a {
        color: #ffffff;
    }


    .navbar.scrolled .nav-btn {
        padding: 3% !important;
        text-align: center;
        width: 120px;
        background: linear-gradient(#ffffff, #fff) padding-box,
            linear-gradient(to right, darkblue, darkorchid) border-box;
        border-radius: 10px;
        border: 2px solid transparent;
    }

   

    .navbar-toggler-icon {
        filter: invert(1);
    }

    .navbar.scrolled .navbar-toggler-icon {
        filter: invert(0);
    }


    .sub-section-h1 {
        font-size: 3rem;
        color: #FFF;
        text-align: center;
        font-weight: 500;
    }

    .sub-section-h2 {
        font-size: 1.5rem;
        color: #FFF;
        text-align: center;
        font-weight: 500;
    }

    .screenshot-title{
        font-size: 1.3rem;
        font-weight: 500;
        margin-left: auto;
        margin-right: auto;
    }


}

@media only screen and (max-width: 400px) {
    .sub-section-h1 {
        font-size: 1.7rem;
        color: #FFF;
        text-align: center;
        font-weight: 500;
    }

    .sub-section-h2 {
        font-size: 1.4rem;
        color: #FFF;
        text-align: center;
        font-weight: 500;
    }

    .screenshot-title{
        font-size: 1rem;
        font-weight: 500;
        margin-left: auto;
        margin-right: auto;
    }

}


@media only screen and (min-width: 768px) and (max-width: 1024px) {

    .hero-content h1 {
        font-size: 2.5rem;
    }

    .hero-content p {
        text-align: center;
        font-weight: 400;
    }

    .hero-content img {
        width: 100%;
        
    }

    .hero-content a {
        font-size: 15px;
        font-weight: 400;
        color: #fff;
        text-decoration: none;
        padding: 1% 2% !important;
        background: linear-gradient(#070d35, #100632) padding-box, linear-gradient(to right, darkblue, darkorchid) border-box;
        border-radius: 10px;
        display: block;
        margin: 0 auto;
        border: 2px solid transparent;
        transition: all .4s ease-in-out;
    }
    

    .hero-content {
        text-align: center;
        padding: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        
    }
    .navbar-toggler-icon {
        filter: invert(1);
    }

    .navbar.scrolled .navbar-toggler-icon {
        filter: invert(0);
    }


    .sub-category-card {
        background-position: center;
    }
    .margin-10-per{
        margin: 5% 0 25%;
    }

}
